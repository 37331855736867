import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { focusManager } from '@accedo/vdkweb-navigation';
import { RoundedButton } from '../../components/rounded-button';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import { getServiceConfig } from '../../sxmservicelayer/service.config.utils';
import './open-access.component.scss';

const okButtonNav = {
  id: 'ok-button',
};

export const OpenAccessExpiredPageComponent = () => {
  const history = useHistory();

  const [appConfig, setAppConfig] = useState({} as any);
  useEffect(() => {
    setAppConfig(getServiceConfig());
  }, []);

  useEffect(() => {
    focusManager.changeFocus(okButtonNav.id);
  }, []);

  const okOnClick = () => {
    history.replace(appRouteConstants.FT_WELCOME);
  };

  return (
    <div className="open-access-expired-page">
      <div className="open-access-expired-logo-container">
        <div>
          <SiriusXMLogo />
        </div>
      </div>
      <div className="open-access-expired-content-container">
        <div className="open-access-expired-text-container">
          <span className="title">
            You'll need to sign in with your current subscription to keep
            streaming.
          </span>
          <span className="subtitle-line-1">
            Visit{' '}
            <span className="subscribe-url">
              {appConfig &&
              appConfig.deviceInfo &&
              appConfig.deviceInfo.appRegion === 'US'
                ? 'streaming.siriusxm.com'
                : 'siriusxm.com/tvtrial'}
            </span>
          </span>
          <span className="subtitle-line-2">to subscribe to the SXM App.</span>
        </div>
      </div>
      <div className="open-access-expired-button-container">
        <RoundedButton
          type="primary"
          className="ok-button"
          nav={okButtonNav}
          onClick={okOnClick}
        >
          OK
        </RoundedButton>
      </div>
    </div>
  );
};
