import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import { RoundedButton } from '../../components/rounded-button';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import { getServiceConfig } from '../../sxmservicelayer/service.config.utils';
import './welcome-page.component.scss';
import {
  ServiceFactory,
  //AuthenticationService,
  InitializationService,
  InitializationStatusCodes,
  //openAccessStatus,
} from '../../servicelib';
import { getPageBackId } from '../../redux/selector/xdk.store';

const pageNav = { id: 'welcome-page' };
const signInButtonNav = {
  id: 'sign-in-button',
  nextdown: 'try-it-out-button',
};
const tryItOutButtonNav = { id: 'try-it-out-button', nextup: 'sign-in-button' };

export const WelcomePageComponent = () => {
  const history = useHistory();
  const [showTryItOutButton /*setShowTryItOutButton*/] = useState(false);
  const [isOpenAccessInProgress, setIsOpenAccessInProgress] = useState(false);

  const [appConfig, setAppConfig] = useState({} as any);
  useEffect(() => {
    setAppConfig(getServiceConfig());
  }, []);

  const handleSignIn = () => {
    history.push(appRouteConstants.AUTH.LOGIN);
  };

  const handleOpenAccess = () => {
    if (!isOpenAccessInProgress) {
      setIsOpenAccessInProgress(true);

      const initializationService = ServiceFactory.getInstance(
        InitializationService,
      ) as InitializationService;

      initializationService.initiateOpenAccess();
      const subscription = initializationService.initState.subscribe(
        initializationState => {
          if (initializationState === InitializationStatusCodes.RUNNING) {
            history.replace(appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION);
          }
        },
      );

      return () => subscription.unsubscribe();
    }
  };

  useEffect(() => {
    focusManager.changeFocus(signInButtonNav.id);

    /*  //TODO: Uncomment once Open Access can allow users to play content
    const authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;

    
    const isOpenStatusEligible =
      authenticationService.session.openAccessStatus ===
      openAccessStatus.ELIGIBLE;
    setShowTryItOutButton(isOpenStatusEligible); 
    */
  }, []);

  /* Handles the press of the BACK button to exit the app */
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [backId]);

  return (
    <Page nav={pageNav} className="welcome-page">
      <div className="welcome-logo-container">
        <div>
          <SiriusXMLogo />
        </div>
      </div>
      <div className="welcome-content-container">
        <div className="welcome-text-container">
          <span>What you love</span>
          <span>is on now.</span>
        </div>
        <div
          className={
            showTryItOutButton
              ? 'welcome-buttons-container'
              : 'welcome-buttons-container open-access-inelegible'
          }
        >
          <RoundedButton
            className={'sign-in-button'}
            nav={
              showTryItOutButton
                ? signInButtonNav
                : {
                    ...signInButtonNav,
                    nextdown: undefined,
                  }
            }
            type={'primary'}
            onClick={handleSignIn}
          >
            Sign In
          </RoundedButton>
          {showTryItOutButton && (
            <div className="button-divider">
              <span>or</span>
            </div>
          )}
          {showTryItOutButton && (
            <RoundedButton
              className={'try-it-out-button'}
              nav={tryItOutButtonNav}
              onClick={handleOpenAccess}
              type={'primary'}
              isLoading={isOpenAccessInProgress}
            >
              Try It Out
            </RoundedButton>
          )}
          {!showTryItOutButton && (
            <div className="subscription-text">
              Visit{' '}
              <span className="subscription-url">
                {appConfig &&
                appConfig.deviceInfo &&
                appConfig.deviceInfo.appRegion === 'US'
                  ? 'streaming.siriusxm.com'
                  : 'siriusxm.ca/tvtrial'}
              </span>{' '}
              to sign up for streaming
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};
