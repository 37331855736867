import { ModuleRequest } from '../http';
import {
  NuDetectPayload,
  AuthenticationConstants,
  IScreenFlow,
} from './authentication.constants';
import { EFreeTierFlow } from '../free-tier';
import { IAppConfig } from '../config';

export class StandardAuth extends ModuleRequest {
  public standardAuth: {
    username: string;
    password: string;
  };

  public nuDetect: NuDetectPayload;
  public screenFlow: IScreenFlow;
  public deviceInfo: any;

  constructor(
    username: string,
    password: string,
    screenName: EFreeTierFlow,
    nuDetectPayload?: NuDetectPayload,
  ) {
    super();

    this.standardAuth = {
      username: username,
      password: password,
    };

    if (nuDetectPayload) {
      this.nuDetect = {
        ndSpmd: JSON.stringify(nuDetectPayload.ndPayload),
        ndSessionId: nuDetectPayload.ndSessionId,
        ndPlacement: AuthenticationConstants.NUDETECT_PLACEMENT,
        ndPlacementPage: AuthenticationConstants.NUDETECT_PLACEMENT_PAGE,
        ndReportingSegment: AuthenticationConstants.NUDETECT_REPORTING_SEGMENT,
      };
    }

    if (screenName) {
      this.screenFlow = {
        name: screenName,
      };
    }
  }

  public setConfig(appConfig: IAppConfig) {
    this.resultTemplate = 'HLSP';
    this.deviceInfo = {
      osVersion: appConfig.deviceInfo.osVersion,
      sxmAppVersion: appConfig.deviceInfo.sxmAppVersion,
      oem: 'LG',
      clientDeviceId: appConfig.deviceInfo.clientDeviceId,
      deviceModel: appConfig.deviceInfo.deviceModel,
      platform: appConfig.deviceInfo.platform,
      clientDeviceType: appConfig.deviceInfo.clientDeviceType,
      deviceMake: appConfig.deviceInfo.deviceMake,
    };
  }
}
