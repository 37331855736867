import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollectionCarousel } from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { useHistory } from 'react-router';
import './collection.component.scss';
import { COLLECTION } from '../../utils/navigationMap';
import { StickyNavbar } from '../../components/sticky-navbar';
import Grid from '../../components/grid/Grid';
import ChannelTile from '../../components/tiles/ChannelTile';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import VerticalScroll from '../../vertical-scroll/verticalScroll';
import SiriusXMLogo from '../../assets/images/sxm-logo-blue@1x.png';

const { PAGE } = COLLECTION;

const pageNav = {
  id: PAGE,
};

export const CollectionPage = props => {
  const { isCoreMenuOpen, setIsCoreMenuOpen } = props;

  const collectionCarousel = useSelector(selectCollectionCarousel);
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const [navIds, setNavIds] = useState({});
  const [animation, setAnimation] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);
  const isAllXtraChannelsView = useRef(null);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    const pathArray = location.pathname.split('/');
    const queryParameters = pathArray[pathArray.length - 1];
    carouselStoreService.selectCollectionCarousel(queryParameters);

    if (
      queryParameters &&
      queryParameters.indexOf('onlyAdditionalChannels') !== -1
    ) {
      isAllXtraChannelsView.current = true;
    }
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id]) {
      forwardFocus.current = lastFocusedTile[pageNav.id];
      focusManager.changeFocus(pageNav.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    let gridId = '';
    if (collectionCarousel && collectionCarousel.zone) {
      const ids = [];
      const carousel = [];
      collectionCarousel.zone.forEach(zone => {
        zone.content.forEach(contentCarousel => {
          gridId = contentCarousel.guid;
          ids.push(contentCarousel.guid);
          for (let i = 0; i < contentCarousel.tiles.length / 5; i++) {
            carousel.push(`ROW-${i}`);
          }
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || undefined,
          nextdown: ids[index + 1],
        };
      });

      navs['page'] = {
        ...pageNav,
      };

      setNavIds(navs);
      setCarouselItems(carousel);
    }

    if (gridId && !lastFocusedTile[pageNav.id]) {
      focusManager.changeFocus(gridId);
    }
  }, [collectionCarousel]);

  if (!navIds && !collectionCarousel.zone) {
    return null;
  }

  return (
    <VerticalScroll
      parent={pageNav.id}
      nav={navIds['page']}
      items={carouselItems}
      navBar={() => document.querySelector('.sticky-navbar')?.clientHeight}
    >
      <Page className="collection-page" nav={navIds['page']}>
        {!isAllXtraChannelsView.current && (
          <StickyNavbar
            isCoreMenuOpen={isCoreMenuOpen}
            children={
              collectionCarousel && collectionCarousel.pageTitle?.textValue
                ? collectionCarousel.pageTitle.textValue
                : ''
            }
          />
        )}
        {isAllXtraChannelsView.current && (
          <>
            <div className="xtra-channels-nav-bar-placeholder" />
            <div className="xtra-channels-nav-bar">
              <span>Xtra Channels</span>
              <img src={SiriusXMLogo} />
            </div>
            <div className="xtra-channels-navbar-blue-divider-container">
              <div className="xtra-channels-navbar-blue-divider" />
            </div>
          </>
        )}
        <div className="collection-content-container">
          {collectionCarousel &&
            collectionCarousel.zone &&
            collectionCarousel.zone.map(zone => {
              return (
                <Fragment key={zone.zoneId}>
                  {zone.content &&
                    zone.content.map(contentCarousel => {
                      return (
                        <Grid
                          pageId={pageNav.id}
                          nav={{
                            ...navIds[contentCarousel.guid],
                          }}
                          data={contentCarousel.tiles}
                          component={ChannelTile}
                          className="content-grid grid-general-style"
                          classNameItemWrapper={''}
                          maxItemsRow={5}
                          forwardFocus={forwardFocus.current}
                          animation={animation}
                          onFocus={id => {
                            if (!animation) {
                              verticalScroll(id);
                            }
                          }}
                          showDefault
                        />
                      );
                    })}
                </Fragment>
              );
            })}
        </div>
      </Page>
    </VerticalScroll>
  );
};
