import React, { useState, useEffect } from 'react';
import { ReactComponent as AudioIcon } from '../../assets/images/audio.svg';
import { ReactComponent as XtraIcon } from '../../assets/images/xtra.svg';
import { ServiceFactory } from '../../servicelib';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { useObservable } from '../../hooks';
import { isBackgroundColorLight } from '../../utils/pageUtils';
import { Marquee } from '../marquee';
import './ChannelTile.scss';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import { focusManager } from '@accedo/vdkweb-navigation';

const ChannelTile = ({
  isFocused,
  clicked,
  isGrid = true,
  data,
  customOnClick,
  nav,
  navId,
  tileClick,
}) => {
  const timer = React.useRef<any>(null);
  const carouselTileService = ServiceFactory.getInstance(
    CarouselTileService,
  ) as CarouselTileService;
  const title = useObservable(data.line2$);
  const subtext = useObservable(data.line3$);

  const [mWidth, setMWidth] = useState(0);
  const widthSetter = w => {
    setMWidth(nw => Math.max(nw, w));
  };

  const grid = isGrid;

  let logoTypeClassName = '';
  if (data.tileContentSubType === 'seededradio') {
    logoTypeClassName = 'channel-card-logo-pandora-station';
  } else if (data.tileContentType === 'page') {
    logoTypeClassName = 'channel-card-page-supercategory-logo';
  } else if (data.bgImageUrl) {
    logoTypeClassName = 'channel-card-logo-se';
  }

  const [isPodcastPage, setIsPodcastPage] = useState(false);
  const isLightColor = isBackgroundColorLight(data.backgroundColor);

  useEffect(() => {
    if (data) {
      setIsPodcastPage(data.tileAssetInfo?.isPandoraPodcast);
    }
  }, [data]);
  const callbackFunction = () => {
    if (customOnClick) {
      customOnClick(data);
    } else {
      carouselTileService.onClickNeriticLinkAction(data);
    }
  };
  useUpdateEffect(() => {
    callbackFunction();
  }, [clicked]);
  const handleMouseOver = () => {
    timer.current = setTimeout(
      () => focusManager.changeFocus(navId || nav.id),
      200,
    );
  };
  const handleMouseOut = () => {
    clearTimeout(timer.current);
  };
  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={() => tileClick()}
      className={`${grid ? 'channel-card-grid' : 'channel-card'} ${
        isFocused ? 'channel-card-focused' : ''
      } ${
        isLightColor
          ? 'channel-card-background-color-fixed'
          : 'channel-card-background-color'
      }`}
      style={{
        backgroundColor: `${
          isLightColor ? 'rgb(9,55,100)' : data.backgroundColor
        }`,
        backgroundImage: data.bgImageUrl
          ? `url("${data.bgImageUrl}?width=264&height=264&preserveAspect=true")`
          : isPodcastPage
          ? `url("${data.fgImageUrl}")`
          : '',
        backgroundSize: data.bgImageUrl || isPodcastPage ? 'cover' : '',
        backgroundPosition: data.bgImageUrl || isPodcastPage ? 'center' : '',
      }}
    >
      {title === 'View All' ? (
        <div className="view-all-arrow">
          <div className="arrow-top" />
          <div className="arrow-down" />
        </div>
      ) : data.fgImageUrl && !isPodcastPage ? (
        <img
          className={`channel-card-logo ${logoTypeClassName}`}
          src={`${data.fgImageUrl}${
            !data.tileAssetInfo?.isPandoraPodcast
              ? '?width=220&height=176&preserveAspect=true'
              : ''
          }`}
          alt={data.imageAltText}
        />
      ) : null}
      {data.tag ? <div className="channel-card-tag">{data.tag}</div> : null}
      {data.fgImageType !== 'promo' && title !== 'View All' ? (
        <div className="channel-card-info">
          {data.line1 && !data.isExtra ? (
            <span
              className={`channel-card-se-details transparent ${
                data.tileContentType === 'page' ? 'explore-title' : ''
              }`}
            >
              {data.bgImageUrl && data.iconImageUrl && (
                <img className="channel-card-icon" src={data.iconImageUrl} />
              )}
              {!isPodcastPage && (
                <h3
                  style={{
                    color: 'white',
                  }}
                >
                  {data.line1}
                </h3>
              )}
            </span>
          ) : null}
          {data.isExtra ? (
            <h3 className="transparent">
              <XtraIcon className="channel-card-icon xtra-icon" />
              Xtra Channel
            </h3>
          ) : null}
          {data.isSE ? (
            <span className="channel-card-se-details transparent">
              <AudioIcon className="channel-card-icon" />
              <h3
                style={{
                  color: 'white',
                }}
              >
                {data.date}
              </h3>
              <span className="dot-icon" />
              <h3
                style={{
                  color: 'white',
                }}
              >
                {data.duration}
              </h3>
            </span>
          ) : null}
          <Marquee
            className="channel-card-title"
            text={title}
            mWidth={mWidth}
            setMWidth={widthSetter}
            isFocused={isFocused}
            lightBackground={false}
          />
          {!data.isSE ? (
            <Marquee
              className={data.isExtra ? 'transparent' : ''}
              text={subtext}
              mWidth={mWidth}
              setMWidth={widthSetter}
              isFocused={isFocused}
              lightBackground={false}
            />
          ) : null}
        </div>
      ) : data.fgImageType !== 'promo' ? (
        <h3 className="view-all-title">View All</h3>
      ) : null}
    </div>
  );
};

export default ChannelTile;

ChannelTile.defaultProps = {
  nav: {},
};
