import { focusManager } from '@accedo/vdkweb-navigation';
import React, { useEffect, useState, useRef } from 'react';
import { FocusNavigationArrow } from '../components/navigation-arrow/NavigationArrow';
import PointerContext, {
  whenPointerStatusChange,
} from '../context/PointerContext';
import { getLastFocusedTileId } from '../redux/selector/xdk.store';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
const slideCount = 1;
const slideCountVertical = 5;
const VerticalScroll = ({
  children,
  nav = {},
  items = [],
  showArrows = true,
  parent = null,
  navBar = null,
  lowHeight = false,
  addGridHeight = 0,
  subSwimlaneHeight = 0,
  isVertical = false,
  isSpecialVertical = false,
  showDefault = false,
  onCustomPointerItemFocus = null,
}) => {
  const lastFocusedTile = useSelector(getLastFocusedTileId);
  const [enabled, setEnabled] = React.useState(false);
  const [, setUpdate] = useState(0);
  const currentPos = useRef({
    index: 0,
    topPos: 0,
    bottomPos: 0,
  });
  const currentPosHorizontal = useRef({
    index: 0,
    newPosition: 0,
    position: 0,
  });
  const currentPosVertical = useRef({
    index: 0,
    newPosition: 0,
    position: 0,
  });
  const enableScroll = evt => {
    if (!enabled) return;
    evt.deltaY > 0 ? scroll(-1) : scroll(1);
  };

  useEffect(() => {
    document.addEventListener('wheel', enableScroll, false);
    return () => {
      document.removeEventListener('wheel', enableScroll, false);
    };
  }, [enabled, items]);
  useEffect(() => {
    whenPointerStatusChange(setEnabled);
  }, []);

  const scroll = React.useCallback(
    direction => {
      if (!navBar) return;
      const navbarHeight = navBar();
      const index = currentPos.current.index;
      const currentIndex =
        typeof index === 'string' ? items.indexOf(index) : index;
      let newIndex = direction === 1 ? currentIndex + 1 : currentIndex - 1;
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex > items?.length - 1) {
        newIndex = items.length;
      }
      if (isEmpty(items)) {
        return;
      }
      const getCarouselItem = document.getElementById(items[newIndex]);
      if (!getCarouselItem) return;
      if (typeof items[newIndex] !== 'string') return;
      if (/^ROW-/g.test(items[newIndex]) && !lowHeight) {
        currentPos.current = {
          index: newIndex,
          topPos:
            getCarouselItem.offsetTop - navbarHeight / 4 + addGridHeight - 40,
          bottomPos:
            getCarouselItem.offsetTop +
            navbarHeight +
            addGridHeight +
            getCarouselItem.clientHeight +
            getCarouselItem.clientHeight / 2,
        };
        window.scrollTo({
          top: getCarouselItem.offsetTop - navbarHeight + addGridHeight,
          behavior: 'smooth',
        });
      } else {
        currentPos.current = {
          index: newIndex,
          topPos: getCarouselItem.offsetTop - 40,
          bottomPos:
            getCarouselItem.offsetTop + getCarouselItem.clientHeight + 100,
        };
        window.scrollTo({
          top: getCarouselItem.offsetTop - subSwimlaneHeight - navbarHeight / 4,
          behavior: 'smooth',
        });
      }
      setUpdate(Math.random());
    },
    [items, currentPos, parent],
  );

  const determineCurPos = (item, idx) => {
    const getCarouselItem = document.getElementById(item);
    if (!navBar) return;
    const navbarHeight = navBar();
    if (getCarouselItem) {
      if (/^ROW-/g.test(item) && !lowHeight) {
        currentPos.current = {
          index: idx,
          topPos:
            getCarouselItem.offsetTop + addGridHeight - navbarHeight / 4 - 40,
          bottomPos:
            getCarouselItem.offsetTop +
            addGridHeight +
            navbarHeight +
            getCarouselItem.clientHeight +
            getCarouselItem.clientHeight / 2,
        };
        if (navbarHeight) {
          window.scrollTo({
            top: getCarouselItem.offsetTop - navbarHeight + addGridHeight,
            behavior: 'smooth',
          });
        }
      } else {
        currentPos.current = {
          index: idx,
          topPos: getCarouselItem.offsetTop + -40,
          bottomPos:
            getCarouselItem.offsetTop + getCarouselItem.clientHeight + 100,
        };
        if (navbarHeight) {
          window.scrollTo({
            top:
              getCarouselItem.offsetTop - subSwimlaneHeight - navbarHeight / 4,
            behavior: 'smooth',
          });
        }
      }
      setUpdate(Math.random());
    }
  };

  const customFunc = item => {
    const id = document.getElementById(item);
    if (id) {
      const parent = document.getElementById(item).closest('[data-name]');
      if (parent) {
        return parent.getAttribute('id');
      }
    }
    return 0;
  };

  useEffect(() => {
    if (!isEmpty(lastFocusedTile)) {
      const text = lastFocusedTile[parent];
      if (text) {
        const getFirst = customFunc(text);
        const idx = items.indexOf(getFirst);
        if (idx >= 0) {
          determineCurPos(items[idx], idx);
        }
      } else {
        const initial = focusManager.getCurrentFocus();
        const getFirst = customFunc(initial);
        const idx = items?.indexOf(getFirst);
        if (idx >= 0) {
          determineCurPos(items[idx], idx);
        } else {
          determineCurPos(items[0], 0);
        }
      }
    } else {
      determineCurPos(items[0], 0);
    }
  }, [nav, lastFocusedTile]);

  const page = React.useCallback(direction => {
    (document.activeElement as any).blur();
    if (direction === 1) {
      const newIndex = currentPosHorizontal.current.index + 1;
      const newCalculatedPos = slideCount + slideCount * newIndex;
      const newPos =
        newCalculatedPos > items.length - 1
          ? items.length - 1
          : newCalculatedPos - 1;
      const mod = Math.floor(newPos / slideCount);
      currentPosHorizontal.current = {
        index: newIndex,
        newPosition: newPos,
        position: mod === 0 ? newPos : newPos + mod,
      };
      const getFirstChild = document
        .querySelector(`#COLUMN-${newPos} > div > div`)
        .getAttribute('id');
      focusManager.changeFocus(getFirstChild);
    } else if (direction === -1) {
      const newIndex = currentPosHorizontal.current.index - 1;
      const newCalculatedPos = slideCount * newIndex;
      const newPos = newCalculatedPos <= 0 ? 0 : newCalculatedPos;
      const mod = newPos % slideCount;
      currentPosHorizontal.current = {
        index: newIndex <= 0 ? 0 : newIndex,
        position: mod === 0 ? newPos : 0,
        newPosition: newPos,
      };
      const getFirstChild = document
        .querySelector(`#COLUMN-${newPos} > div > div`)
        .getAttribute('id');
      focusManager.changeFocus(getFirstChild);
    }
    setUpdate(Math.random());
  }, []);

  const scrollVerticalCarousel = React.useCallback(direction => {
    (document.activeElement as any).blur();
    if (direction === 1) {
      const newIndex = currentPosVertical.current.index + 1;
      const newCalculatedPos =
        slideCountVertical + slideCountVertical * newIndex;
      const newPos =
        newCalculatedPos > items.length - 1
          ? items.length - 1
          : newCalculatedPos - 1;
      const mod = Math.floor(newPos / slideCountVertical);
      currentPosVertical.current = {
        index: newIndex,
        newPosition: newPos,
        position: mod === 0 ? newPos : newPos + mod,
      };
      focusManager.changeFocus(`GRID-ITEM-0${newPos}`);
    } else if (direction === -1) {
      const newIndex = currentPosVertical.current.index - 1;
      const newCalculatedPos = slideCountVertical * newIndex;
      const newPos = newCalculatedPos <= 0 ? 0 : newCalculatedPos;
      const mod = newPos % slideCountVertical;
      currentPosVertical.current = {
        index: newIndex <= 0 ? 0 : newIndex,
        position: mod === 0 ? newPos : 0,
        newPosition: newPos,
      };
      focusManager.changeFocus(`GRID-ITEM-0${newPos}`);
    }
    onCustomPointerItemFocus();
    setUpdate(Math.random());
  }, []);

  useEffect(() => {
    const unlistenToFocusChanged = focusManager.listenToFocusChanged(data => {
      const text = data.currentFocus;
      if (text) {
        const getFirst = customFunc(text);
        const idx = items.indexOf(getFirst);
        if (idx >= 0) {
          determineCurPos(items[idx], idx);
        }
      }
    });
    return () => {
      unlistenToFocusChanged;
    };
  }, [items]);

  useEffect(() => {
    whenPointerStatusChange(setEnabled);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {showArrows ? (
        <PointerContext>
          {children}
          {isSpecialVertical ? (
            <>
              <FocusNavigationArrow
                up
                nav={nav}
                rowVerticalUp
                rowUp
                onClick={() => scrollVerticalCarousel(-1)}
                style={{
                  visibility:
                    currentPosVertical.current.newPosition > 0
                      ? 'visible'
                      : 'hidden',
                }}
              />

              <FocusNavigationArrow
                down
                rowDown
                rowVerticalDown
                nav={nav}
                onClick={() => scrollVerticalCarousel(1)}
                style={{
                  visibility:
                    currentPosVertical.current.newPosition < items?.length - 1
                      ? 'visible'
                      : 'hidden',
                }}
              />
            </>
          ) : (
            <>
              {isVertical ? (
                <>
                  {items.length > 2 && (
                    <>
                      <FocusNavigationArrow
                        left
                        nav={nav}
                        onClick={() => {
                          page(-1);
                        }}
                        style={{
                          visibility:
                            currentPosHorizontal.current.newPosition > 0
                              ? 'visible'
                              : 'hidden',
                        }}
                      />

                      <FocusNavigationArrow
                        right
                        nav={nav}
                        onClick={() => {
                          page(1);
                        }}
                        style={{
                          visibility:
                            currentPosHorizontal.current.newPosition <
                            items?.length - 1
                              ? 'visible'
                              : 'hidden',
                        }}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <FocusNavigationArrow
                    up
                    nav={nav}
                    onClick={() => scroll(-1)}
                    rowUp
                    style={{
                      top: `${currentPos.current.topPos}px`,
                      visibility:
                        currentPos.current.index > 0 ? 'visible' : 'hidden',
                    }}
                  />
                  <FocusNavigationArrow
                    down
                    nav={nav}
                    onClick={() => scroll(1)}
                    rowDown
                    style={{
                      top: `${currentPos.current.bottomPos}px`,
                      visibility:
                        currentPos.current.index < items?.length - 1
                          ? 'visible'
                          : 'hidden',
                    }}
                  />
                </>
              )}
            </>
          )}
        </PointerContext>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default VerticalScroll;
