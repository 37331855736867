import React from 'react';
import classNames from 'classnames';
import { ClientCodes, ServiceFactory } from '../../servicelib';
import { useHistory } from 'react-router-dom';

import styles from './tiles.module.scss';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { itemType } from '../../pages/search-page/search-page-utils';
import { TuneClientService } from '../../sxmservicelayer/tune/tune.client.service';
import { zoneCategory } from '../navigation-menu/navigation-menu-utils';

const SearchResultTile = ({ isFocused, clicked, data, tileClick }) => {
  const history = useHistory();

  const tuneClientService = ServiceFactory.getInstance(
    TuneClientService,
  ) as TuneClientService;

  useUpdateEffect(() => {
    if (data.type === itemType.SEARCH_ITEM) {
      history.push(`/search-result/${encodeURIComponent(data.text)}`);
    } else if (data.type === itemType.VIDEO_ITEM) {
      tuneClientService
        .tune({
          channel: data.payload,
          channelId: data.payload.channelId,
          contentType: data.payload.type,
        })
        .subscribe((responseCode: number) => {
          if (responseCode === ClientCodes.SUCCESS) {
            if (!location.pathname.includes(zoneCategory.discoverZone.path)) {
              history.push('/now-playing');
            }
          }
        });
    } else if (data.type === itemType.CLEAR_ITEM) {
      data.onClick();
    }
  }, [clicked]);

  return (
    <div onClick={() => tileClick()}>
      {data.type === itemType.SEARCH_ITEM && (
        <div
          className={classNames(styles['keyword-box'], {
            [styles.focused]: isFocused,
          })}
        >
          <div className={styles['item-centered']}>
            {typeof data.src === 'object' ? (
              React.createElement(data.src, styles['result-icon'])
            ) : (
              <img src={data.src} className={styles['result-icon']} />
            )}
          </div>
          <div className={styles['item-centered']}>
            <span className={styles.keyword}>{data.text}</span>
          </div>
        </div>
      )}
      {data.type === itemType.CLEAR_ITEM && (
        <div
          className={classNames(styles['keyword-box'], {
            [styles.focused]: isFocused,
          })}
        >
          <div className={styles['item-centered']}>
            <span className={styles.clear}>{data.text}</span>
          </div>
        </div>
      )}
      {data.type === itemType.VIDEO_ITEM && (
        <div
          className={classNames(styles['keyword-box'], {
            [styles.focused]: isFocused,
          })}
        >
          <div className={styles['item-centered']}>
            {typeof data.src === 'object' ? (
              React.createElement(data.src, styles['tune-icon'])
            ) : (
              <img src={data.src} className={styles['tune-icon']} />
            )}
          </div>
          <div className={styles['item-centered']}>
            <span className={styles.tune}>{data.text}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResultTile;
