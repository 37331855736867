import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllChannelsCarousel } from '../../redux/selector/carousel.store';
import { ServiceFactory } from '../../servicelib';
import { CarouselStoreService } from '../../sxmservicelayer/carousel-store/carousel.store.service';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getLastFocusedTileId,
  getPageBackId,
} from '../../redux/selector/xdk.store';
import { useHistory } from 'react-router';
import './all-channels.component.scss';
import { ALLCHANNELS } from '../../utils/navigationMap';
import { StickyNavbar } from '../../components/sticky-navbar';
import Grid from '../../components/grid/Grid';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import ChannelTile from '../../components/tiles/ChannelTile';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { verticalScroll } from '../../utils/pageUtils';
import VerticalScroll from '../../vertical-scroll/verticalScroll';

const { PAGE } = ALLCHANNELS;

const pageNav = {
  id: PAGE,
};

export const AllChannelsPage = props => {
  const { isCoreMenuOpen, setIsCoreMenuOpen } = props;

  const allChannels = useSelector(selectAllChannelsCarousel);
  const carouselStoreService = ServiceFactory.getInstance(
    CarouselStoreService,
  ) as CarouselStoreService;

  const [navIds, setNavIds] = useState({});
  const [carouselItems, setCarouselItems] = useState([]);
  const [animation, setAnimation] = useState(true);
  const dispatch = useDispatch();
  const forwardFocus = useRef(null);

  useEffect(() => {
    setIsCoreMenuOpen(false);
    carouselStoreService.selectAllChannelsCarousels();
  }, []);

  /** Handles the BACK button navigation logic **/
  const history = useHistory();
  const backId = useSelector(getPageBackId);

  useUpdateEffect(() => {
    history.goBack();
    focusManager.changeFocus('all-channels-button');
  }, [backId]);

  /* Reestablished the focused to the last focused tile before the page was exited */
  const lastFocusedTile = useSelector(getLastFocusedTileId);

  useEffect(() => {
    if (history.action === 'POP') {
      setAnimation(false);
    }

    if (lastFocusedTile[pageNav.id]) {
      forwardFocus.current = lastFocusedTile[pageNav.id];
      focusManager.changeFocus(pageNav.id);
      dispatch(
        saveLastFocusedTileId({ ...lastFocusedTile, [pageNav.id]: null }),
      );
    }

    setTimeout(() => {
      setAnimation(true);
    }, 1000);
  }, [history.location]);

  useEffect(() => {
    let gridId = '';
    if (allChannels && allChannels.selectors) {
      const ids = [];
      const carouselFull = [];
      allChannels.selectors.forEach(selector => {
        selector.segments.forEach(segment => {
          if (segment.class === 'channels') {
            segment.carousels.forEach(carousel => {
              gridId = carousel.guid;
              ids.push(carousel.guid);
              for (let i = 0; i < carousel.tiles.length / 5; i++) {
                carouselFull.push(`ROW-${i}`);
              }
            });
          }
        });
      });

      const navs = {};
      ids.forEach((id, index) => {
        navs[id] = {
          id: id,
          nextup: ids[index - 1] || undefined,
          nextdown: ids[index + 1],
        };
      });

      navs['page'] = {
        ...pageNav,
        forwardFocus: ids[0],
      };

      setNavIds(navs);
      setCarouselItems(carouselFull);
    }
    if (gridId && !lastFocusedTile[pageNav.id]) {
      focusManager.changeFocus(gridId);
    }
  }, [allChannels]);

  if (!allChannels) {
    return null;
  }
  return (
    <VerticalScroll
      nav={navIds}
      items={carouselItems}
      parent={navIds['page']?.id}
      navBar={() => document.querySelector('.sticky-navbar')?.clientHeight}
      showDefault
    >
      <Page className="all-channels-page" nav={navIds['page']}>
        <StickyNavbar children="All Channels" isCoreMenuOpen={isCoreMenuOpen} />
        <div className="all-channels-content-container">
          {allChannels &&
            allChannels.selectors &&
            allChannels.selectors.map(selector => {
              return (
                <>
                  {selector.segments &&
                    selector.segments.map(segment => {
                      return (
                        <>
                          {segment.class === 'channels' &&
                            segment.carousels &&
                            segment.carousels.map(carousel => {
                              return (
                                <Grid
                                  pageId={pageNav.id}
                                  nav={{
                                    ...navIds[carousel.guid],
                                  }}
                                  data={carousel.tiles}
                                  component={ChannelTile}
                                  className="content-grid grid-general-style"
                                  classNameItemWrapper={''}
                                  maxItemsRow={5}
                                  forwardFocus={forwardFocus.current}
                                  animation={animation}
                                  onFocus={id => {
                                    if (!animation) {
                                      verticalScroll(id);
                                    }
                                  }}
                                  showDefault
                                />
                              );
                            })}
                        </>
                      );
                    })}
                </>
              );
            })}
        </div>
      </Page>
    </VerticalScroll>
  );
};
