import React, { useState } from 'react';
import { ServiceFactory } from '../../servicelib';
import { Marquee } from '../marquee';
import { CarouselTileService } from '../../sxmservicelayer/carousel-tile/carousel-tile.service';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import styles from './EpisodeTile.module.scss';
import { useObservable } from '../../hooks';
import { focusManager } from '@accedo/vdkweb-navigation';

const EpisodeTile = props => {
  const { clicked, data, isFocused, customOnItemFocus, nav, tileClick } = props;

  const timer = React.useRef<any>(null);
  const carouselTileService = ServiceFactory.getInstance(
    CarouselTileService,
  ) as CarouselTileService;

  if (isFocused) {
    customOnItemFocus(data);
  }

  const title = useObservable(data.line2$);

  const [mWidth, setMWidth] = useState(0);
  const widthSetter = w => {
    setMWidth(nw => Math.max(nw, w));
  };

  useUpdateEffect(() => {
    carouselTileService.onClickNeriticLinkAction(data);
  }, [clicked]);

  const isPodcast = data.tileContentSubType === 'podcast';
  const tileImageUrl = isPodcast ? data.fgImageUrl : data.bgImageUrl;
  const tileImageUrlParams = isPodcast
    ? ''
    : '?width=256&height=144&preserveAspect=true';

  const handleMouseOver = () => {
    timer.current = setTimeout(() => focusManager.changeFocus(nav.id), 200);
  };
  const handleMouseOut = () => {
    clearTimeout(timer.current);
  };
  return (
    <div
      className={`${styles['channel-card']} ${
        isFocused ? `${styles['channel-card-focused']}` : ''
      }`}
      onClick={() => tileClick()}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {tileImageUrl && data.iconImageUrl && (
        <div className={styles['channel-card-img-container']}>
          <img
            className={
              isPodcast
                ? styles['channel-card-img-podcast']
                : styles['channel-card-img']
            }
            src={`${tileImageUrl}${tileImageUrlParams}`}
            alt={data.imageAltText}
          />
          <img
            className={styles['channel-card-icon']}
            src={`${data.iconImageUrl}?width=24&height=24&preserveAspect=true`}
          />
        </div>
      )}
      <div className={styles['channel-card-info']}>
        {data.line1 && (
          <span className={styles['channel-card-details transparent']}>
            <h3
              style={{
                color: 'white',
              }}
            >
              {data.line1}
            </h3>
          </span>
        )}
        <Marquee
          className={styles['channel-card-title']}
          text={title}
          mWidth={mWidth}
          setMWidth={widthSetter}
          isFocused={isFocused}
          lightBackground={false}
        />
      </div>
    </div>
  );
};

export default EpisodeTile;
