import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ReactComponent as SiriusXMLogo } from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import './login-page.component.scss';

export const LoginConfirmationPageComponent = ({ setScanForBypass }) => {
  const history = useHistory();

  useEffect(() => {
    of(appRouteConstants.HOME_FORYOU)
      .pipe(delay(5000))
      .subscribe(route => {
        history.replace(route);
        setScanForBypass(true);
      });
  }, []);

  return (
    <div className="login-confirmation-page">
      <div className="login-confirmation-logo-container">
        <div>
          <SiriusXMLogo />
        </div>
      </div>
      <div className="login-confirmation-content-container">
        <div className="login-confirmation-text-container">
          <span className="title">Welcome to SiriusXM</span>
          <span className="subtitle">
            You're ready to listen to 200+ channels!
          </span>
        </div>
      </div>
    </div>
  );
};
